import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../redux/reducers";
import {DatePicker, Divider, Form, Image, Table} from "antd";
import {fetchJobs} from "../../redux/actions";
import {fetchJobsPayload, MappedJob, ProcessingJob, User} from "../../types";
import {PaginationProps} from "antd/lib/pagination";
import {jobsFetchEnterpriseData, jobsPagination} from "../../redux/selectors/userSelector";
import _ from "lodash";
import {OPERATIONS} from "../Ratings";
import dayjs from "dayjs";

const getFullDate = (date: string): string => {
  const dateAndTime = date.split('T');

  return `${dateAndTime[0].split('-').reverse().join('-')} ${dateAndTime[1].replace('Z', '')}`;
};

const parseDate = (date?: dayjs.Dayjs) => {
  if(!date) return
  return date.toDate()
}

const Jobs: React.FC<Props> = ({
  sortOrder,
  pagination,
  jobs,
  fetchJobs,
  user,
  jobsFetchEnterpriseData,
}) => {
  const { email } = user;
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(1, "day")
  );
  const [toDate, setToDate] = useState();
  useEffect(() => {
    fetchJobs({
      page: 1,
      limit: 10,
      sort: {timestamp: -1},
      paginationEnabled: true,
      search: {
        'user.email': email,
        status: 'received,deleted',
        source: 'neuroapi',
      },
      from: parseDate(fromDate),
      to: parseDate(toDate)
    } as fetchJobsPayload);
  }, [fetchJobs, email, jobsFetchEnterpriseData, fromDate, toDate]);

  const getSortOrderFromValue = (
    value: number
  ): "ascend" | "descend" | undefined => {
    if (value === -1) return "descend";
    if (value === 1) return "ascend";
    return undefined;
  };

  const handleTableChange = (
    pagination: PaginationProps,
    sorter: any,
    filters: any
  ) => {
    if (pagination.pageSize && pagination.current) {
      // let sort: any = {};
      // if (
      //   filters &&
      //   filters.column &&
      //   filters.column.dataIndex &&
      //   filters.order
      // ) {
      //   sort[filters.column.dataIndex] = filters.order === "ascend" ? 1 : -1;
      // }
      fetchJobs({
        page: pagination.current,
        limit: pagination.pageSize,
        sort: {timestamp: -1},
        paginationEnabled: true,
        search: {
          'user.email': email,
          status: 'received,deleted',
          source: 'neuroapi',
        },
        from: parseDate(fromDate),
        to: parseDate(toDate)
      } as fetchJobsPayload);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "timestamp",
      // sorter: (a: Job, b: Job) =>
      //   moment(a.timestamp).unix() - moment(b.timestamp).unix(),
      // sortOrder: sortOrder.timestamp
      //   ? getSortOrderFromValue(sortOrder.timestamp)
      //   : null,
      render: ((date:string) => getFullDate(date))
    },
    {
      title: "Hash",
      dataIndex: "hash",
      key: "hash",
      render: (hash: any, data: MappedJob) => {
        return <a href={`https://deep-image.ai/app/application/options/images/${hash}`} target="_blank" rel="noreferrer">{hash}</a>;
      },
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      render: ((channel:string, row: any) => `${channel || 'web'} - ${row.is_mobile ? 'mobile': 'desktop'}`)
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: ((action:string, row: any) => action === 'batch_transform' ? `Batch - ${row.batch_total_images} images` : action)
    },
    {
      title: "Cost",
      dataIndex: "result",
      key: "cost",
      render: ((result: Array<ProcessingJob>) => {
        const cost = _.filter(result, (job: ProcessingJob) => job.status === 'complete' && job.source === 'deep_image')
        return cost.length > 0 && cost[0].result ? cost[0].result.cost : 'N/A'
      })
    },
    {
      title: "Rating",
      dataIndex: "result",
      key: "rating",
      render: ((result: Array<ProcessingJob>) => {
        const cost = _.filter(result, (job: ProcessingJob) => job.status === 'complete' && job.source === 'deep_image')
        return cost.length > 0 && cost[0].rating ? cost[0].rating : 'N/A'
      })
    },
    {
      title: "Status",
      dataIndex: "result",
      key: "result",
      render: ((result: Array<ProcessingJob>, row: any) => {
        const isDeleted = row.status === 'deleted'
        const isCompleted = _.some(result, (job: ProcessingJob) => job.status === 'complete' && job.source === 'neuroapi')
        const isFailed = _.some(result, (job: ProcessingJob) => job.status === 'failed' && job.source === 'neuroapi')
        return isDeleted ? 'deleted' : isCompleted ? 'complete':
          isFailed ? 'fail': 'not completed';
      })
    },
    {
      title: "Balance",
      dataIndex: "user",
      key: "user",
      render: ((user:any, row: any) => user.subscription.plan_usage.deep_image.available_requests + user.purchased_credits)
    },
    {
      title: "Operations",
      dataIndex: "data",
      key: "operations",
      render: (data: any, row: any) => {
        const parseOperations = (data: any) => {
          const transStr = data.transformations // @ts-ignore
              .map((trans: string) => OPERATIONS[trans])
              .join(",")

          let generatorStr = ''
          if (data.background?.generate?.adapter_type) {
            generatorStr = `generate_${data.background.generate.adapter_type}`
          } else if (!data.background?.generate?.adapter_type && !data.image_id) {
            generatorStr = 'generate'
          } else if (data.background?.generate && !data.background?.generate?.adapter_type && data.image_id) {
            generatorStr = 'generate_background'
          }

          return `${transStr},${generatorStr}`
        }

        return data && data.transformations
          ? parseOperations(data)
          : "N/A";
      },
    },
    {
      title: "Image source",
      dataIndex: "result",
      key: "origin",
      render: ((result: Array<ProcessingJob>, row: any) => {
        const processed = _.filter(result, (job: ProcessingJob) => job.status === 'complete' && job.source === 'deep_image')
        let img_url

        if (row.action === 'transform') {
          img_url = processed.length > 0 && processed[0].result ? processed[0].result.origin_url: ''
        } else if (row.action === 'batch_transform') {
          img_url = processed.length > 0 && processed[0].result ? processed[0].result.result_url: ''

        } else if (row.action === 'ecommerce_transform') {
          img_url = processed.length > 0 && processed[0].result ? processed[0].result.result_url: ''
        }

        return img_url ?
          <Image
            width={100}
            src={img_url}
          />: <div/>
      })
    },
    {
      title: "Image result",
      dataIndex: "result",
      key: "result",
      render: ((result: Array<ProcessingJob>, row: any) => {
        const processed = _.filter(result, (job: ProcessingJob) => job.status === 'complete' && job.source === 'deep_image')
        let img_url

        img_url = processed.length > 0 && processed[0].result ? processed[0].result.result_url: ''

        if (img_url) {
          img_url = img_url.replace('minio', 'localhost')
          img_url = img_url.replace('http://ain.teonite.net/neuroapi-store/', 'https://deep-image.ai/images/')
        }

        return row.action === 'batch_transform' ?
          <a href={img_url} target="_blank">Download</a> :
          <Image
            width={100}
            src={img_url}
          />
      })
    },
  ];

  return (
    <React.Fragment key={String(jobsFetchEnterpriseData)}>
      <h3>Jobs</h3>
      <Divider />
      <Form layout="inline" style={{marginBottom: 24}}>
        <Form.Item label="From:">
          <DatePicker
            showTime
            maxDate={dayjs()}
            value={fromDate}
            format="YYYY-MM-DD HH:mm"
            onChange={(v) => setFromDate(v)}
          />
        </Form.Item>
        <Form.Item label="To:">
          <DatePicker
            showTime
            value={toDate}
            format="YYYY-MM-DD HH:mm"
            minDate={fromDate}
            maxDate={dayjs()}
            onChange={(v) => setToDate(v)}
          />
        </Form.Item>
      </Form>
      <Table
        dataSource={jobs}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          ...pagination,
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
        }}
        bordered
        rowKey={(value) => value._id}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  fetchJobs: fetchJobs,
};

const mapStateToProps = (state: RootState) => ({
  jobs: state.userJobs.jobs,
  pagination: jobsPagination(state),
  sortOrder: state.userJobs.sort,
  jobsFetchEnterpriseData: jobsFetchEnterpriseData(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { user: User };

export default connector(Jobs);
