import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {fetchConversionPaths, getUserDetails, toggleEnterpriseData} from '../../redux/actions';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  userDetails,
  isUserSet,
  userHasPlan,
  subscriptionCredits,
  totalCredits,
  totalSubscriptionCredits,
  purchasedCredits,
  isUserSuspended,
  isAccountInactive,
  hasAdminPanelAccess,
  stripeCustomerDashboardUrl,
  userPlan, isEnterpriseAccount, userFetchEnterpriseData
} from '../../redux/selectors/userSelector';
import Credits from './Credits';
import ActionMenu from './ActionMenu';
import ActionModal from './ActionModal';
import SubscriptionDetails from './SubscriptionDetails';
import Plan from './Plan';
import Account from './Account';
import Loader from '../Common/Loader';
import ActionHistory from './actions/ActionHistory';
import {Row, Col, Switch} from 'antd';
import CodesUsageHistory from './CodesUsageHistory';
import Jobs from './Jobs';
import ConversionPaths from "./ConversionPaths";

type Props = ReturnType<typeof mapState> & typeof mapDispatch & RouteComponentProps<{ id: string }> & { userId?: string };

const Details: React.FunctionComponent<Props> = ({
  match,
  user,
  userId,
  isUserSet,
  getUserDetails,
  isUserSuspended,
  hasAdminPanelAccess,
  stripeCustomerDashboardUrl,
  userFetchEnterpriseData,
  toggleEnterpriseData,
  isEnterpriseAccount,
  fetchConversionPaths
}) => {
  useEffect(() => {
    const id = userId || match.params.id
    getUserDetails(id);
    fetchConversionPaths(id)
  }, [userId, getUserDetails, match.params.id, userFetchEnterpriseData, fetchConversionPaths]);

  if (!isUserSet) return <Loader message='Loading user details' />;

  return (
    <div className='user-details-container'>
      <ActionModal />
      <ActionMenu />

      <div className='user-details'>
        <h2 className='user-details-header'>
          <span>{user.email} {user.shopify && `(${user.shopify.email})`}</span>
          {stripeCustomerDashboardUrl ?
            <a
              href={stripeCustomerDashboardUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              View in Stripe
            </a> : null}
        </h2>
        <div>
          {isUserSuspended &&
            <h3 className='font-red'>ACCOUNT HAS BEEN SUSPENDED</h3>}
          {hasAdminPanelAccess &&
            <h3 className='front-blue'>User has access to the admin panel</h3>}
          {isEnterpriseAccount &&
            <h3 className='front-blue'>Enterprise data <Switch value={userFetchEnterpriseData} onChange={toggleEnterpriseData}></Switch></h3>}
        </div>
        <Row gutter={2}>
        <Col span={12}>
          <Account />
        </Col>
          <SubscriptionDetails />
        <Col span={12}>
          <Plan />
        </Col>
        <Col span={12}>
          <Credits />
        </Col>
        <Col span={24}>
          <Jobs user={user}/>
        </Col>
        <Col span={24}>
          <CodesUsageHistory user={user}/>
        </Col>
        </Row>
        <ActionHistory />
        <ConversionPaths />
      </div>
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  user: userDetails(state),
  userPlan: userPlan(state),
  isUserSet: isUserSet(state),
  userHasPlan: userHasPlan(state),
  purchasedCredits: purchasedCredits(state),
  subscriptionCredits: subscriptionCredits(state),
  totalSubscriptionCredits: totalSubscriptionCredits(state),
  totalCredits: totalCredits(state),
  isUserSuspended: isUserSuspended(state),
  isAccountInactive: isAccountInactive(state),
  hasAdminPanelAccess: hasAdminPanelAccess(state),
  stripeCustomerDashboardUrl: stripeCustomerDashboardUrl(state),
  isEnterpriseAccount: isEnterpriseAccount(state),
  userFetchEnterpriseData: userFetchEnterpriseData(state)
})

const mapDispatch = {
  getUserDetails,
  toggleEnterpriseData,
  fetchConversionPaths
}

export default connect(mapState, mapDispatch)(withRouter(Details));
