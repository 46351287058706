import { authorizationHeaders } from "../utils/api";
import { API } from "../config";
import {
  fetchEventGroupsType,
  fetchEventActionsType,
  fetchEventGroupsSelectType,
  fetchEventActionsSelectType,
  groupPaginationConfig,
  EventGroupSearch,
  ShopifyProductsList,
  ImageStatsList,
  PlatformsList,
  QuestionnaireConfig, ApplicablePlans, PromptList, PromptTag, PaymentList, UtmSourceList,
} from "../types";
import axios from "axios";
import { getAuthToken } from "../utils/storage";
import { map } from "lodash";
import { useMutation, useQuery } from "react-query";

export const queryTypes = {
  EVENT_GROUP_LIST: "eventGroupsList",
  EVENT_ACTIONS_LIST: "eventActionsList",
  EVENT_ACTION_TYPES: "eventActionTypes",
  EVENT_ACTION_NAME_LIST: "eventActionsNameList",
  MAUTIC_EMAILS_LIST: "mauticEmailsList",
  SHOPIFY_PRODUCTS_LIST: "shopifyProductsList",
  IMAGE_STATS: "imageStatsList",
  PLATFORMS_LIST: "platformsList",
  PROMPTS_LIST: "promptsList",
  PROMPT_TAGS_LIST: "promptTagsList",
  PROMPT_CATEGORIES_LIST: "promptCategoriesList",
  PAYMENTS_LIST: "paymentsList",
  UTM_SOURCES_LIST: "utmSoucesList",
};

export const fetchEventGroups = async (
  key: string,
  search: EventGroupSearch,
  options: groupPaginationConfig
): Promise<fetchEventGroupsType> => {
  const params = {
    search,
    options,
  };

  const token = getAuthToken();
  if (!token) return {} as fetchEventGroupsType;

  const { data } = await axios.get(API("events/groups"), {
    ...authorizationHeaders(token),
    ...{
      params: params,
    },
  });
  return data;
};

export const fetchEventActions = async (
  key: string,
  page: number,
  search: string,
  select: fetchEventActionsSelectType | undefined,
  pagination: boolean
): Promise<fetchEventActionsType> => {
  const params = {
    page: page || 1,
    sort: { createdAt: -1 },
    search: search,
    select: select,
    pagination: pagination,
  };
  const token = getAuthToken();
  if (!token) return {} as fetchEventActionsType;

  const res = await axios
    .get(API("events/actions"), {
      ...authorizationHeaders(token),
      params,
    })
    .catch((e) => {
      throw Error(e.message);
    });
  return res.data;
};

export const fetchEventActionTypes = async (key: string): Promise<any> => {
  const token = getAuthToken();
  if (!token) return [];
  const { data } = await axios.get(
    API("events/actions/types"),
    authorizationHeaders(token)
  );
  return data.docs;
};

export const fetchMauticEmails = async (): Promise<any> => {
  const token = getAuthToken();
  if (!token) return {};
  const { data } = await axios.get(
    API("events/mautic/emails"),
    authorizationHeaders(token)
  );
  return data;
};

export const fetchShopifyProductsQuery = async (): Promise<
  ShopifyProductsList
> => {
  const token = getAuthToken();
  if (!token)
    return {
      items: [
        {
          _id: "",
          key: 0,
          id: "",
          name: "",
          billing: "",
          images: 0,
          price: 0,
        },
      ],
    };
  const { data } = await axios.get(
    API("shopify/products/"),
    authorizationHeaders(token)
  );
  const transformedData = map(data, (product) => {
    let transformedProduct = {
      _id: product._id,
      id: product.id,
      name: product.name,
      billing: product.providers.shopify.billing,
      images: product.providers.shopify.images,
      price: product.price,
    };
    return transformedProduct;
  });
  return <ShopifyProductsList>{ items: transformedData };
};

export const fetchImageStatsQuery = async (): Promise<ImageStatsList> => {
  const token = getAuthToken();
  if (!token) return { items: [] };
  const { data } = await axios.get(
    API("image_stats/"),
    authorizationHeaders(token)
  );
  return <ImageStatsList>data;
};

export const fetchPlatformsQuery = async (): Promise<PlatformsList> => {
  const token = getAuthToken();
  if (!token)
    return {
      items: [
        {
          _id: "",
          name: "",
          key: 0,
          width: 0,
          height: 0,
          remove_background: false,
        },
      ],
    };

  const { data } = await axios.get(
    API("platforms"),
    authorizationHeaders(token)
  );
  return <PlatformsList>{ items: data };
};

const getQuestionnaireConfig = async (): Promise<QuestionnaireConfig> => {
  const token = getAuthToken();
  if (!token) return {} as QuestionnaireConfig;
  const res = await axios.get<QuestionnaireConfig>(
    API(`questionnaire/config`),
    authorizationHeaders(token)
  );
  return res.data;
};

export const useGetQuestionnaireConfig = (params = {}) => {
  return useQuery(useGetQuestionnaireConfig.key, getQuestionnaireConfig, {
    refetchOnWindowFocus: false,
    ...params,
  });
};

useGetQuestionnaireConfig.key = ["questionnaire"];

export const getDistinctCreditAmountFromPlans = async (): Promise<
  ApplicablePlans
> => {
  const token = getAuthToken();
  if (!token) {
    throw Error("Authorization token required");
  }
  const { data } = await axios.get<ApplicablePlans>(
    API("types/plans-credit-amount"),
    authorizationHeaders(token)
  );
  return data.sort((a, b) => a > b ? 1 : -1) as ApplicablePlans;
};

export const useGetDistinctCreditAmountFromPlans = (params = {}) => {
  return useQuery(
    useGetDistinctCreditAmountFromPlans.key,
    getDistinctCreditAmountFromPlans,
    {
      refetchOnWindowFocus: false,
      ...params,
    }
  );
};

useGetDistinctCreditAmountFromPlans.key = ["plans-credits-amount"];

export const fetchPromptsQuery = async (): Promise<PromptList> => {
  const token = getAuthToken();
  if (!token)
    return {
      items: [
        {
          _id: "id",
          description: "description",
          group: "group",
          image_path: "image_path",
          name: "name",
          ordering: 1,
          thumbnail_path: "string",
          face_id: false
        },
      ],
    };

  const { data } = await axios.get(
    API("prompts"),
    authorizationHeaders(token)
  );
  return <PromptList>{ items: data };
};

export const fetchPromptTagsQuery = async () => {
  const token = getAuthToken();
  if (!token) {
    throw Error("Authorization token required");
  }

  const { data } = await axios.get<PromptTag[]>(
    API("prompts/tag"),
    authorizationHeaders(token)
  );
  return data;
};

export const fetchPromptCategoriesQuery = async () => {
  const token = getAuthToken();
  if (!token) {
    throw Error("Authorization token required");
  }

  const { data } = await axios.get<string[]>(
    API("prompts/categories"),
    authorizationHeaders(token)
  );
  return data;
};


export const fetchPaymentsQuery = async (  key: string,
  page: number,
  limit: number,
  sort: string,
  filters: any
): Promise<PaymentList> => {
  const token = getAuthToken();
  if (!token) {
    throw Error("Authorization token required")
  }

  const params = {
    page,
    limit,
    sort,
    filters
  }

  const { data } = await axios.get(API("payments"), {
    ...authorizationHeaders(token),
    ...{
      params: params,
    },
  });

  return <PaymentList>data;
};

export const fetchUtmSourcesQuery = async (  key: string): Promise<UtmSourceList> => {
  const token = getAuthToken();
  if (!token) {
    throw Error("Authorization token required")
  }

  const { data } = await axios.get(API("payments/utm_sources"), {
    ...authorizationHeaders(token),
  });

  return <UtmSourceList>data;
};
