import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.scss';
// import 'antd/dist/antd.css';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from './serviceWorker';
import { history, store } from './redux/store';
import { getAuthToken } from './utils/storage';
import AppLayout from './components/AppLayout';
import Routes from './routes';
import { getAdminProfile } from './redux/actions/index';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import dayjs from 'dayjs'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

// check if token is present in localStorage
const token = getAuthToken();
if (token) {
  store.dispatch(getAdminProfile());
}

// axios will set xsrf headers for us in all future requests
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const App: React.FunctionComponent = () => (
  <>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppLayout>
          <Routes />
        </AppLayout>
      </ConnectedRouter>
    </Provider>
  </>
);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
