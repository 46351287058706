import { createAction } from '@reduxjs/toolkit';
import {
  User,
  UserSearchResponse,
  UserAction,
  UpdatedCredits,
  UserActionSearchResponse,
  PromoCode,
  PromoType,
  PromoUsage,
  SearchType,
  CodeSearchPayload,
  CodeUsageSearchPayload,
  CodeTypeSearchPayload,
  FetchPromoTypesMinimalSuccessPayload,
  GeneratePromoCodesPayload,
  PromoCodeCreatePayload,
  PromoTypeCreatePayload,
  SearchPromoUsages,
  SearchNotifications,
  NotificationsSearchPayload,
  NotificationVerifyPayload,
  CreateNotificationPayload,
  EditNotificationPayload,
  SendIconPayload,
  fetchPromoCodesMinimalSuccessPayload,
  fetchActiveJobsPayload,
  setMaintenanceActivePayload,
  UserChannelsPayload,
  fetchJobsPayload,
  JobsPayload,
  ShopifyProduct,
  fetchJobsRatingsPayload, DeleteIconPayload, ConversionPath, ConversionPathsPayload
} from '../../types';

export type PaginationPayload = { page: number; pageSize: number };
export type LoginSuccessPayload = { token: string; is_new_user?: boolean, user: User };
export type LoginPayload = { password: string, username: string };
export type GoogleOAuthPayload = { access_token: string, };
export type GoogleOAuthSuccessPayload = { token: string, user: User };
export type UserSearchPayload = { search: string, page: number, limit: number, payableUser?: boolean, enterprise?: boolean };
export type UserActionSearchPayload = { page: number };

export const locationChange = createAction('@@router/LOCATION_CHANGE');
export const login = createAction<LoginPayload>("LOGIN");
export const loginSuccess = createAction<LoginSuccessPayload>('LOGIN_SUCCESS');
export const loginFailed = createAction('LOGIN_FAILED');

export const loginGoogleOAuth = createAction<GoogleOAuthPayload>('LOGIN_GOOGLE_OAUTH');
export const loginGoogleOAuthSuccess = createAction<GoogleOAuthSuccessPayload>('LOGIN_GOOGLE_OAUTH_SUCCESS');

export const logout = createAction('LOGOUT');

export const getAdminProfile = createAction('GET_ADMIN_PROFILE');

export const toggleEnterpriseData = createAction("TOGGLE_ENTERPRISE_DATA")

export const getUsers = createAction<UserSearchPayload | undefined>('GET_USERS');
export const getUsersSuccess = createAction<UserSearchResponse>('GET_USERS_SUCCESS');
export const goToUserDetails = createAction<string>('GO_TO_USER_DETAILS');
export const getUserDetails = createAction<string>('GET_USER_DETAILS');
export const setUserDetailsSuccess = createAction<User>('SET_USER_DETAILS_SUCCESS');
export const setUserAction = createAction<UserAction>('SET_USER_ACTION');
export const clearUserAction = createAction('CLEAR_USER_ACTION');
export const userActionSuccess = createAction<User>('USER_ACTION_SUCCESS');
export const setUserActionComment = createAction<string>('SET_USER_ACTION_COMMENT');
export const setUpdatedCredits = createAction<UpdatedCredits>('SET_UPDATED_CREDITS');

export const suspendAccount = createAction('SUSPEND_ACCOUNT');
export const toggleEnterprise = createAction('TOGGLE_ENTERPRISE');
export const manageCredits = createAction('MANAGE_CREDITS');
export const activateSuspendedAccount = createAction('ACTIVATE_SUSPENDED_ACCOUNT');
export const sendAccountActivationEmail = createAction('SENND_ACCOUNT_ACTIVATION_EMAIL');
export const sendPasswordResetEmail = createAction('SEND_PASSWORD_RESET_EMAIL');
export const grantAdminPanelAccess = createAction('GRANT_ADMIN_PANEL_ACCESS');
export const revokeAdminPanelAccess = createAction('REVOKE_ADMIN_PANEL_ACCESS');
export const cancelStripeSubscription = createAction('CANCEL_STRIPE_SUBSCRIPTION');
export const restoreStripeSubscription = createAction('RESTORE_STRIPE_SUBSCRIPTION');
export const activateNewAccount = createAction('ACTIVATE_NEW_ACCOUNT');

export type PromoCodesSuccessPayload = { codes : Array<PromoCode> };
export type FailedPayload = { message : string };

export const getUserActions = createAction<UserActionSearchPayload | undefined>('GET_USER_ACTIONS');
export const getUserActionsSuccess = createAction<UserActionSearchResponse>('GET_USER_ACTIONS_SUCCESS');
export const fetchPromoCodes = createAction<SearchType | undefined>('FETCH_PROMO_CODES');
export const fetchPromoCodesSuccess = createAction<CodeSearchPayload>('FETCH_PROMO_CODES_SUCCESS');
export const fetchPromoCodesFailed = createAction<FailedPayload>('FETCH_PROMO_CODES_FAILED');

export type PromoTypesSuccessPayload = {types: Array<PromoType>};

export const blacklistDomain = createAction<string>('BLACK_LIST_DOMAIN');
export const blacklistDomainSuccess = createAction('BLACK_LIST_DOMAIN_SUCCESS');
export const blacklistDomainError = createAction<string>('BLACK_LIST_DOMAIN_ERROR');
export const clearDomainState = createAction('CLEAR_DOMAIN_STATE');
export const fetchPromoTypes = createAction<SearchType | undefined>('FETCH_PROMO_TYPES');
export const fetchPromoTypesSuccess = createAction<CodeTypeSearchPayload>('FETCH_PROMO_TYPES_SUCCESS');
export const fetchPromoTypesFailed = createAction<FailedPayload>('FETCH_PROMO_TYPES_FAILED');

export type PromoUsageSuccessPayload = {usages: Array<PromoUsage>};

export const fetchPromoUsages = createAction<SearchPromoUsages | undefined>('FETCH_PROMO_USAGES');
export const fetchPromoUsagesSuccess = createAction<CodeUsageSearchPayload>('FETCH_PROMO_USAGES_SUCCESS');
export const fetchPromoUsagesFailed = createAction<FailedPayload>('FETCH_PROMO_USAGES_FAILED');

export const editPromoType = createAction<PromoType>('EDIT_PROMO_TYPE');
export const editPromoTypeSuccess = createAction('EDIT_PROMO_TYPE_SUCCESS');
export const editPromoTypeFailed = createAction<FailedPayload>('EDIT_PROMO_TYPE_FAILED');

export const editPromoCode = createAction<PromoCode>('EDIT_PROMO_CODE');
export const editPromoCodeSuccess = createAction('EDIT_PROMO_CODE_SUCCESS');
export const editPromoCodeFailed = createAction<FailedPayload>('EDIT_PROMO_CODE_FAILED');

export const deletePromoCode = createAction<string>('DELETE_PROMO_CODE');
export const deletePromoCodeSuccess = createAction('DELETE_PROMO_CODE_SUCCESS');
export const deletePromoCodeFailed = createAction<FailedPayload>('DELETE_PROMO_CODE_FAILED');

export const createPromoCode = createAction<PromoCodeCreatePayload>('CREATE_PROMO_CODE');
export const createPromoCodeSuccess = createAction('CREATE_PROMO_CODE_SUCCESS');
export const createPromoCodeFailed = createAction<FailedPayload>('CREATE_PROMO_CODE_FAILED');

export const createPromoType = createAction<PromoTypeCreatePayload>('CREATE_PROMO_TYPE');
export const createPromoTypeSuccess = createAction('CREATE_PROMO_TYPE_SUCCESS');
export const createPromoTypeFailed = createAction<FailedPayload>('CREATE_PROMO_TYPE_FAILED');

export const deletePromoType = createAction<string>('DELETE_PROMO_TYPE');
export const deletePromoTypeSuccess = createAction('DELETE_PROMO_TYPE_SUCCESS');
export const deletePromoTypeFailed = createAction<FailedPayload>('DELETE_PROMO_TYPE_FAILED');

export const setPromoCodeSearch = createAction<string>('SET_PROMO_CODE_SEARCH');

export const fetchPromoTypesMinimal = createAction('FETCH_PROMO_TYPES_MINIMAL');
export const fetchPromoTypesMinimalSuccess = createAction<FetchPromoTypesMinimalSuccessPayload>('FETCH_PROMO_TYPES_MINIMAL_SUCCESS');
export const fetchPromoTypesMinimalFailed = createAction<FailedPayload>('FETCH_PROMO_TYPES_MINIMAL_FAILED');

export const generatePromoCodes = createAction<GeneratePromoCodesPayload>('GENERATE_PROMO_CODES');
export const generatePromoCodesSuccess = createAction('GENERATE_PROMO_CODES_SUCCESS');
export const generatePromoCodesFailed = createAction<FailedPayload>('GENERATE_PROMO_CODES_FAILED');

export const fetchNotifications = createAction<SearchNotifications>('FETCH_NOTIFICATIONS');
export const fetchNotificationsSuccess = createAction<NotificationsSearchPayload>('FETCH_NOTIFICATIONS_SUCCESS');
export const fetchNotificationsFailed = createAction<FailedPayload>('FETCH_NOTIFICATIONS_FAILED');

export const createNotification = createAction<CreateNotificationPayload>('CREATE_NOTIFICATION');
export const createNotificationSuccess = createAction('CREATE_NOTIFICATION_SUCCESS');
export const createNotificationFailed = createAction<FailedPayload>('CREATE_NOTIFICATION_FAILED');

export const editNotification = createAction<EditNotificationPayload>('EDIT_NOTIFICATION');
export const editNotificationSuccess = createAction('EDIT_NOTIFICATION_SUCCESS');
export const editNotificationFailed = createAction<FailedPayload>('EDIT_NOTIFICATION_FAILED');

export const verifyNotification = createAction<NotificationVerifyPayload>('VERIFY_NOTIFICATION');
export const verifyNotificationSuccess = createAction('VERIFY_NOTIFICATION_SUCCESS');
export const verifyNotificationFailed = createAction<FailedPayload>('VERIFY_NOTIFICATION_FAILED');

export const deleteNotification = createAction<string>('DELETE_NOTIFICATION');
export const deleteNotificationSuccess = createAction('DELETE_NOTIFICATION_SUCCESS');
export const deleteNotificationFailed = createAction<FailedPayload>('DELETE_NOTIFICATION_FAILED');

export const notificationsFormFinish = createAction('NOTIFICATIONS_FORM_FINISH');

export const finishNotificationForm = createAction('FINISH_NOTIFICATION_FORM');

export const sendIcon = createAction<SendIconPayload>('SEND_ICON');
export const sendIconSuccess = createAction('SEND_ICON_SUCCESS');
export const sendIconFailed = createAction<FailedPayload>('SEND_ICON_FAILED');

export const deleteIcon = createAction<DeleteIconPayload>('DELETE_ICON');
export const deleteIconSuccess = createAction('DELETE_ICON_SUCCESS');
export const deleteIconFailed = createAction('DELETE_ICON_FAILED');

export const fetchPromoCodesMinimal = createAction('FETCH_PROMO_CODES_MINIMAL');
export const fetchPromoCodesMinimalSuccess = createAction<fetchPromoCodesMinimalSuccessPayload>('FETCH_PROMO_CODES_MINIMAL_SUCCESS');
export const fetchPromoCodesMinimalFailed = createAction<FailedPayload>('FETCH_PROMO_CODES_MINIMAL_FAILED');

export const fetchActiveJobs = createAction('FETCH_ACTIVE_JOBS');
export const fetchActiveJobsSuccess = createAction<fetchActiveJobsPayload>('FETCH_ACTIVE_JOBS_SUCCESS');
export const fetchActiveJobsFailed = createAction<FailedPayload>('FETCH_ACTIVE_JOBS_FAILED');

export const setMaintenance = createAction<boolean>('SET_MAINTENANCE')
export const setMaintenanceActive = createAction<setMaintenanceActivePayload>('SET_MAINTENANCE_ACTIVE');
export const setMaintenanceActiveSuccess = createAction('SET_MAINTENANCE_ACTIVE_SUCCESS');
export const setMaintenanceActiveFailed = createAction<FailedPayload>('SET_MAINTENANCE_ACTIVE_FAILED');

export const setMaintenanceDisabled = createAction('SET_MAINTENANCE_DISABLED');
export const setMaintenanceDisabledSuccess = createAction('SET_MAINTENANCE_DISABLED_SUCCESS');
export const setMaintenanceDisabledFailed = createAction<FailedPayload>('SET_MAINTENANCE_DISABLED_FAILED');

export const getUserChannelsStats = createAction<string>('GET_USER_CHANNELS_STATS');
export const getUserChannelsSuccess = createAction<UserChannelsPayload>('GET_USER_CHANNELS_STATS_SUCCESS');
export const getUserChannelsFailed = createAction<FailedPayload>('GET_USER_CHANNELS_FAILED');

export const fetchJobs = createAction<fetchJobsPayload | undefined>('FETCH_JOBS');
export const fetchJobsSuccess = createAction<JobsPayload>('FETCH_JOBS_SUCCESS');
export const fetchJobsFailed = createAction<FailedPayload>('FETCH_JOBS_FAILED');

export const fetchRatingJobs = createAction<fetchJobsPayload | fetchJobsRatingsPayload | undefined>('FETCH_RATING_JOBS');
export const fetchRatingJobsSuccess = createAction<JobsPayload>('FETCH_RATING_JOBS_SUCCESS');
export const fetchRatingJobsFailed = createAction<FailedPayload>('FETCH_RATING_JOBS_FAILED');

export const fetchAllRatingJobs = createAction<fetchJobsPayload | fetchJobsRatingsPayload | undefined>('FETCH_ALL_RATING_JOBS');
export const fetchAllRatingJobsSuccess = createAction<JobsPayload>('FETCH_ALL_RATING_JOBS_SUCCESS');
export const fetchAllRatingJobsFailed = createAction<FailedPayload>('FETCH_ALL_RATING_JOBS_FAILED');

export const fetchShopifyProducts = createAction<ShopifyProduct>('FETCH_SHOPIFY_PRODUCTS');

export const fetchConversionPaths = createAction<string>('FETCH_CONVERSION_PATHS');

export const fetchConversionPathsSuccess = createAction<ConversionPathsPayload>('FETCH_CONVERSION_PATHS_SUCCESS');

export const fetchConversionPathsFailed = createAction<ConversionPathsPayload>('FETCH_CONVERSION_PATHS_FAILED');
