import React from 'react';
import { connect } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {
  userDetails,
  isStripeCustomer,
  userChannels
} from '../../redux/selectors/userSelector';

type Props = ReturnType<typeof mapState>;

const Account: React.FunctionComponent<Props> = ({
  user,
  isStripeCustomer,
  userChannels,
}) => {
  if (!user) return null;

  return (
    <div className="user-details-credits">
      <h3 className="subscription-details-header">Account</h3>
      <div className="user-details-credits-content">
        <div className="user-details-credits-content-info">
          <h4>Identifiers:</h4>
          <div className="card-identifiers">
            <p>Database: {user._id}</p>
            {isStripeCustomer && <p>Stripe: {user.stripe.customer}</p>}
          </div>
          <h4 className="card-field-highlight">API key: {user.api_key}</h4>
          <p>Enterprise: {!!user?.enterprise ? "YES" : "NO"} </p>
          <p>Google login: {user.google_id ? "YES" : "NO"}</p>
          <p>Email confirmed: {user.email_confirmed ? "YES" : "NO"}</p>
          <p>Account suspended: {user.disabled ? "YES" : "NO"}</p>
          <p>
            Admin panel access:{" "}
            {user.permissions.adminPanelAccess ? "YES" : "NO"}
          </p>
          <p>Api transformations: {userChannels.api} </p>
          <p>Web transformations: {userChannels.web} </p>
          <p>Processed Jobs Count: {user?.processedJobsCount} </p>
          <p>UTM: {`${user.utm?.source || ''} ${user.utm?.medium || ''} ${user.utm?.campaign || ''}`}</p>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  user: userDetails(state),
  isStripeCustomer: isStripeCustomer(state),
  userChannels: userChannels(state),
});

export default connect(mapState)(Account);
