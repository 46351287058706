import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import {RootStateType, store} from '../../redux/store';
import {Button, DatePicker, Flex, Form, InputNumber, Space, Switch, Table} from 'antd';
import { usersTable, userPagination } from '../../redux/selectors/userSelector';
import { getUsers, UserSearchPayload, goToUserDetails } from '../../redux/actions/index';
import { PaginationProps } from 'antd/lib/pagination';
import Details from "./Details";
import moment from "moment/moment";
import Search from "antd/lib/input/Search";
import dayjs from "dayjs";

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const columns = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (value: any, record: any) => {
      return (
        <Button
          type="link"
          onClick={() => store.dispatch(goToUserDetails(record.id))}
        >
          {value}
        </Button>
      );
    },
  },
  // {
  //   title: 'ID',
  //   dataIndex: 'id',
  //   key: 'id'
  // },
  {
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Plan",
    dataIndex: "plan",
    key: "plan",
  },
  {
    title: "Is plan paid",
    dataIndex: "isPaidPlanActive",
    key: "isPaidPlanActive",
  },
  {
    title: "Total credits",
    dataIndex: "totalCredits",
    key: "totalCredits",
  },
  {
    title: "Purchased",
    dataIndex: "purchasedCredits",
    key: "purchasedCredits",
  },
  {
    title: "Subscription",
    dataIndex: "subscriptionCredits",
    key: "subscriptionCredits",
  },
  {
    title: "Stripe ID",
    dataIndex: "stripe",
    key: "stripe",
  },
  {
    title: "UTM Source - Medium - Campaign",
    dataIndex: "utm",
    key: "utm",
    render: (text: string, data: any) => {
      return `${data?.utm?.source || ""} ${data?.utm?.medium || ""} ${
        data?.utm?.campaign || ""
      }`;
    },
  },
];

const parseDate = (date?: dayjs.Dayjs) => {
  if(!date) return
  return date.toDate()
}

const Users: React.FunctionComponent<Props> = ({
  users,
  pagination,
  getUsers,
}) => {
  const [payable, setPayable] = useState(false);
  const [enterprise, setEnterprise] = useState(false)
  const [expandedRows, setExpandedRows] = useState<string[]>([])
  const [search, setSearch] = useState<string>()
  const expandedRowRef = useRef<HTMLDivElement>(null);
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(1, "day")
  );
  const [toDate, setToDate] = useState();
  const [processedJobsCount, setProcessedJobsCount] = useState<string | number>()


  useEffect(() => {
    getUsers({
      page: pagination.current,
      limit: pagination.pageSize,
      payableUser: payable,
      search,
      enterprise,
      to: parseDate(toDate) ,
      from: parseDate(fromDate),
      processedJobsCount
    } as UserSearchPayload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsers, payable, enterprise, search, fromDate, toDate, processedJobsCount]);

  const handleTableChange = (pagination: PaginationProps) => {
    if (pagination.pageSize && pagination.current) {
      getUsers({
        page: pagination.current,
        limit: pagination.pageSize,
        payableUser: payable,
        search,
        enterprise,
        to: parseDate(toDate),
        from: parseDate(fromDate),
        processedJobsCount
      } as UserSearchPayload);
    }
  }

  return (
    <div>
      <Form layout="inline" style={{ marginBottom: 16, padding: "0 8px", rowGap: 8 }}>
        <Form.Item>
          <Search
            placeholder="Search users by email"
            onSearch={(v) => setSearch(v)}
          />
        </Form.Item>
        <Form.Item label="From:">
          <DatePicker
            showTime
            maxDate={dayjs()}
            value={fromDate}
            format="YYYY-MM-DD HH:mm"
            onChange={(v) => setFromDate(v)}
          />
        </Form.Item>
        <Form.Item label="To:">
          <DatePicker
            showTime
            value={toDate}
            format="YYYY-MM-DD HH:mm"
            minDate={fromDate}
            maxDate={dayjs()}
            onChange={(v) => setToDate(v)}
          />
        </Form.Item>
        <Form.Item>
          <Search
            placeholder="Processed Jobs Count"
            onSearch={(v) => setProcessedJobsCount(v)}
          />
        </Form.Item>
        <Form.Item label="Subscription:">
          <Switch
            onChange={(e) => {
              setPayable(e);
            }}
          />
        </Form.Item>
        <Form.Item label="Enterprise:">
          <Switch value={enterprise} onChange={setEnterprise} />
        </Form.Item>
      </Form>
      {/* <Space direction="vertical">*/}
      {/*  <span>From:</span>*/}
      {/*  <DatePicker*/}
      {/*    style={{ width: 200 }}*/}
      {/*    showTime*/}
      {/*    defaultValue={moment(payload.search.from)}*/}
      {/*    format="YYYY-MM-DD HH:mm"*/}
      {/*    onChange={onChangeFromDate}*/}
      {/*  />*/}
      {/*</Space>*/}
      {/*<Space direction="vertical">*/}
      {/*  <span>To:</span>*/}
      {/*  <DatePicker*/}
      {/*    style={{ width: 200 }}*/}
      {/*    showTime*/}
      {/*    format="YYYY-MM-DD HH:mm"*/}
      {/*    onChange={onChangeToDate}*/}
      {/*  />*/}
      {/*</Space>*/}
      <Table
        expandable={{
          expandedRowKeys: expandedRows,
          onExpand: (expanded, record) => {
            setExpandedRows(expanded ? [record.key] : []);
            setTimeout(() => {
              expandedRowRef.current?.parentElement?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);
          },
          expandedRowRender: (record) => (
            <div ref={expandedRowRef}>
              <Details userId={record.id} />
            </div>
          ),
        }}
        dataSource={users}
        columns={columns}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  users: usersTable(state),
  pagination: userPagination(state)
})

const mapDispatch = {
  getUsers,
}

export default connect(mapState, mapDispatch)(Users);
