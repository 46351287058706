import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import {
  fetchPromptCategoriesQuery,
  fetchPromptsQuery,
  fetchPromptTagsQuery,
  queryTypes,
} from "../../query/queries";
import {
  QueryCache,
  ReactQueryCacheProvider,
  useMutation,
  useQuery,
} from "react-query";
import { Prompt, PromptTag } from "../../types";
import {
  addPromptTag,
  deletePrompt,
  deletePromptTag,
  updatePromptTag,
} from "../../query/mutations";
import { getAuthToken } from "../../utils/storage";
import axios from "axios";
import { API } from "../../config";
import { authorizationHeaders } from "../../utils/api";
import { ReactQueryDevtools } from "react-query-devtools";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { usePrevious } from "../../hooks/usePrevious";

const { TextArea } = Input;

const PromptGroups = [
  "Avatars",
  "Backgrounds",
  "Generator",
  "Doodle",
  "Realestate",
  "Productphoto",
  "Businessphoto",
];

const Prompts = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [
    isPromptTagEditModalVisible,
    setIsPromptTagEditModalVisible,
  ] = useState(false);
  const [
    isPromptTagDeleteModalVisible,
    setIsPromptTagDeleteModalVisible,
  ] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt | undefined>(
    undefined
  );
  const prevSelectedPrompt = usePrevious(selectedPrompt);
  const [selectedTag, setSelectedTag] = useState<PromptTag | undefined>(
    undefined
  );

  const { data: items } = useQuery(
    [queryTypes.PROMPTS_LIST],
    fetchPromptsQuery
  );

  const { data: promptCategories } = useQuery(
    [queryTypes.PROMPT_CATEGORIES_LIST],
    fetchPromptCategoriesQuery,
    {}
  );

  const promptCategoriesOptions = useMemo(
    () =>
      promptCategories && promptCategories.length
        ? promptCategories.sort().map((p) => ({
            label: p,
            value: p,
          }))
        : [],
    [promptCategories]
  );

  const { data: promptTags } = useQuery(
    [queryTypes.PROMPT_TAGS_LIST],
    fetchPromptTagsQuery,
    {}
  );
  const promptTagsOptions = useMemo(
    () =>
      promptTags && promptTags.length
        ? promptTags
            .map((p) => ({
              ...p,
              label: p.name,
              value: p.id,
            }))
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
        : [],
    [promptTags]
  );

  const [form] = Form.useForm();

  const [deleteAction] = useMutation(deletePrompt, {
    onSuccess: () => {
      promptsCache.invalidateQueries([queryTypes.PROMPTS_LIST]);
      message.success("Prompt deleted!", 2);
    },
    onError: () => {
      message.error("Error check api logs for more info.", 2);
    },
  });

  const [addTagInput, setAddTagInput] = useState<string | undefined>();
  const [addTag, addTagState] = useMutation(addPromptTag, {
    onSuccess: () => {
      promptsCache.refetchQueries([queryTypes.PROMPT_TAGS_LIST]);
      message.success("Prompt tag added!", 2);
    },
    onError: () => {
      message.error("Error check api logs for more info.", 2);
    },
  });

  const editPrompt = async (prompt: Prompt): Promise<any> => {
    const token = getAuthToken();
    if (!token) return {};

    console.log("editPrompt", prompt);

    let res;
    if (prompt._id) {
      res = await axios.put(
        API(`prompts`),
        prompt,
        authorizationHeaders(token)
      );
    } else {
      res = await axios.post(
        API(`prompts`),
        prompt,
        authorizationHeaders(token)
      );
    }

    return res.data;
  };

  useEffect(() => {
    const tags = selectedPrompt?.tags || [];
    form.setFields([
      {
        name: "tags",
        value: [
          ...tags,
          ...(form.getFieldValue("tags") || []).filter(
            (t: string) => !tags.includes(t)
          ),
        ].filter((p) => promptTagsOptions.some((t) => t.id === p)),
      },
    ]);
  }, [form, selectedPrompt?.tags, promptTagsOptions]);

  useEffect(() => {
    form.setFields([
      { name: "name", value: selectedPrompt?.name },
      { name: "description", value: selectedPrompt?.description },
      { name: "group", value: selectedPrompt?.group },
      { name: "ordering", value: selectedPrompt?.ordering },
      { name: "image_path", value: selectedPrompt?.image_path },
      { name: "face_id", value: selectedPrompt?.face_id },
      { name: "category", value: selectedPrompt?.category },
    ]);
  }, [form, selectedPrompt]);

  const openEditModal = (prompt: Prompt) => {
    setSelectedPrompt(prompt);
    if (
      !prevSelectedPrompt ||
      (prevSelectedPrompt && prevSelectedPrompt._id !== prompt._id)
    )
      form.setFieldValue(
        "tags",
        (prompt?.tags || []).filter((p) =>
          promptTagsOptions.some((t) => t.id === p)
        )
      );
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [savePrompt] = useMutation(editPrompt, {
    onSuccess: () => {
      promptsCache.invalidateQueries([queryTypes.PROMPTS_LIST]);
    },
    onError: () => {
      console.error(`Error occurred, check logs`);
    },
  });

  // const getSortedProducts = ({
  //   group = null,
  // }) => {
  //   const suppliedList = items?.items
  //     .filter((p) => {
  //       if (group) {
  //         return p.group === group;
  //       }
  //       return true;
  //     })
  //     .map((p) => {
  //       return p;
  //     });
  //   return suppliedList!
  // };

  const [fetchedData, setFetchedData] = useState<Prompt[] | undefined>(
    undefined
  );

  useEffect(() => {
    setFetchedData(items?.items);
  }, [items]);

  const onFinish = (values: any) => {
    setIsModalVisible(false);
    values._id = selectedPrompt?._id;
    values.category = Array.isArray(values?.category) ? values?.category?.[0] : values?.category;
    savePrompt(values);
    setFetchedData(items?.items);
    setSelectedPrompt(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
      filters: PromptGroups.map((group) => {
        return { text: group, value: group };
      }),
      onFilter: (value: any, record: any) => record.group.indexOf(value) === 0,
    },
    {
      title: "Ordering",
      dataIndex: "ordering",
      key: "ordering",
      sorter: (a: any, b: any) => a.ordering - b.ordering,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "FaceID",
      dataIndex: "face_id",
      key: "face_id",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail_path",
      key: "thumbnail_path",
      render: (result: string, row: any) => {
        let img_url = result;

        if (img_url) {
          img_url = img_url.replace("minio", "localhost");
          img_url = img_url.replace(
            "http://ain.teonite.net/neuroapi-store/",
            "https://deep-image.ai/images/"
          );
        }

        return <Image width={100} src={img_url} />;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (prompt: Prompt) => (
        <>
          <Button type="primary" onClick={() => openEditModal(prompt)}>
            Edit
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure to delete this prompt?"
            onConfirm={() => deleteAction(prompt._id)}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="default">Delete</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const getEmptyPrompt = (): Prompt => {
    return {
      description: "",
      group: "",
      image_path: "",
      ordering: items?.items.length! + 1,
      name: "",
      face_id: true,
      thumbnail_path: "",
    };
  };

  return (
    <>
      <PromptTagEdit
        isModalOpen={isPromptTagEditModalVisible}
        setIsModalOpen={setIsPromptTagEditModalVisible}
        selectedTag={selectedTag}
      />
      <PromptTagDelete
        isModalOpen={isPromptTagDeleteModalVisible}
        setIsModalOpen={setIsPromptTagDeleteModalVisible}
        selectedTag={selectedTag}
      />
      <Modal
        title="Edit Prompt"
        visible={isModalVisible}
        okButtonProps={{ disabled: addTagState.isLoading }}
        okText="Save"
        onOk={() => {
          form.submit();
        }}
        onCancel={handleCancel}
        width="1000px"
        //         footer={(_, { OkBtn, CancelBtn }) => (
        //   <>
        //     <Button type="primary">Add tag</Button>
        //     <CancelBtn />
        //     <OkBtn />
        //   </>
        // )}
      >
        <Space>
          <Form
            form={form}
            name="update_product"
            size="small"
            // labelCol={{
            //   span: 12,
            // }}
            initialValues={selectedPrompt}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row>
              <Col>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TextArea cols={100} rows={7} />
                </Form.Item>

                <Form.Item
                  label="Ordering"
                  name="ordering"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type={"number"} />
                </Form.Item>

                <Form.Item
                  label="FaceId"
                  name="face_id"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item label="Image path (optional)" name="image_path">
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Group"
                  name="group"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    {PromptGroups.map((group) => {
                      return (
                        <Select.Option key={group} value={group}>
                          {group}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Tags" name="tags">
                  <Select
                    style={{ width: "100%" }}
                    filterOption
                    optionFilterProp="label"
                    options={promptTagsOptions}
                    mode="multiple"
                    optionRender={(option) => (
                      <Space>
                        {option.data.label}
                        {option.data.id ? (
                          <>
                            <Tooltip title="Rename tag">
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedTag(option.data as PromptTag);
                                  setIsPromptTagEditModalVisible(true);
                                }}
                                shape="circle"
                                icon={<EditOutlined />}
                              />
                            </Tooltip>
                            <Tooltip title="Delete tag!">
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedTag(option.data as PromptTag);
                                  setIsPromptTagDeleteModalVisible(true);
                                }}
                                danger
                                shape="circle"
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </>
                        ) : null}
                      </Space>
                    )}
                    dropdownRender={(menu) => {
                      return (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space style={{ padding: "0 8px 4px" }}>
                            <Input
                              placeholder="Please enter name"
                              value={addTagInput}
                              onChange={(e) => setAddTagInput(e.target.value)}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              onClick={(e) => {
                                e.stopPropagation();
                                addTag(addTagInput).then(() =>
                                  setAddTagInput("")
                                );
                              }}
                              loading={addTagState.isLoading}
                              disabled={
                                !addTagInput ||
                                addTagState.isLoading ||
                                promptTagsOptions.some(
                                  (t) =>
                                    (t.name || "").toLowerCase() ===
                                    (addTagInput || "").toLowerCase()
                                )
                              }
                            >
                              Add item
                            </Button>
                            <Button
                              style={{ marginLeft: "auto" }}
                              onClick={() =>
                                navigator.clipboard
                                  .writeText(
                                    JSON.stringify(form.getFieldValue("tags"))
                                  )
                                  .then(() =>
                                    message.success(
                                      "Tags copied successfully",
                                      2
                                    )
                                  )
                                  .catch(() =>
                                    message.error("Could not copy tags", 2)
                                  )
                              }
                            >
                              Copy tags
                            </Button>
                            <Button
                              onClick={() => {
                                navigator.clipboard
                                  .readText()
                                  .then((data) => {
                                    const tags = JSON.parse(
                                      data
                                    ).filter((p: string) =>
                                      promptTagsOptions.some((t) => t.id === p)
                                    );
                                    form.setFieldValue("tags", tags);
                                    message.success(
                                      "Tags pasted successfully",
                                      2
                                    );
                                  })
                                  .catch(() =>
                                    message.error("Could not paste tags", 2)
                                  );
                              }}
                            >
                              Paste tags
                            </Button>
                          </Space>
                        </>
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item label="Category" name="category">
                  <Select
                    style={{ width: "100%" }}
                    mode="tags"
                    maxCount={1}
                    options={promptCategoriesOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Space>
      </Modal>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={2}>
              <h1 className="tab-header">Prompts</h1>
            </Col>
            <Col span={14}></Col>
            <Col span={8}>
              <Button
                type="primary"
                className="btn-position-right"
                onClick={() =>
                  openEditModal({
                    ...getEmptyPrompt(),
                    group: selectedPrompt?.group || "Avatars",
                  })
                }
              >
                Add new prompt
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table columns={columns} dataSource={fetchedData} />
    </>
  );
};

const PromptTagEdit = ({
  isModalOpen,
  setIsModalOpen,
  selectedTag,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTag?: PromptTag;
}) => {
  const [updateTag, updateTagState] = useMutation(updatePromptTag, {
    onSuccess: () => {
      setIsModalOpen(false);
      promptsCache.refetchQueries([queryTypes.PROMPT_TAGS_LIST]);
      message.success("Prompt tag updated!", 2);
    },
    onError: () => {
      message.error("Error check api logs for more info.", 2);
    },
  });
  const [inputValue, setInputValue] = useState<string | undefined>();

  useEffect(() => {
    setInputValue(selectedTag?.name);
  }, [isModalOpen, selectedTag?.name]);

  const handleOk = () => {
    if (selectedTag && inputValue)
      updateTag({ id: selectedTag.id, name: inputValue }).then();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const isDisabled =
    !inputValue || inputValue === selectedTag?.name || updateTagState.isLoading;

  return (
    <>
      <Modal
        title="Rename tag"
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={{
          disabled: isDisabled,
          loading: updateTagState.isLoading,
        }}
        cancelButtonProps={{ disabled: updateTagState.isLoading }}
        onCancel={handleCancel}
        centered
      >
        <Input
          placeholder="Tag name"
          defaultValue={selectedTag?.name}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Modal>
    </>
  );
};

const PromptTagDelete = ({
  isModalOpen,
  setIsModalOpen,
  selectedTag,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTag?: PromptTag;
}) => {
  const [deleteTag, deleteTagState] = useMutation(deletePromptTag, {
    onSuccess: () => {
      promptsCache.refetchQueries([queryTypes.PROMPT_TAGS_LIST]);
      message.success("Prompt tag deleted!", 2);
      setIsModalOpen(false);
    },
    onError: () => {
      message.error("Error check api logs for more info.", 2);
    },
  });

  const handleOk = () => {
    if (selectedTag) deleteTag(selectedTag.id).then();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={`Delete "${selectedTag?.name}" tag`}
        open={isModalOpen}
        onOk={handleOk}
        okText="Delete"
        cancelButtonProps={{ disabled: deleteTagState.isLoading }}
        okButtonProps={{
          disabled: deleteTagState.isLoading,
          loading: deleteTagState.isLoading,
        }}
        onCancel={handleCancel}
        centered
      ></Modal>
    </>
  );
};

export const promptsCache = new QueryCache();

// @ts-ignore
const withPromptsCache = (WrappedComponent) => {
  // @ts-ignore
  const WithPromptsCache = (props) => {
    return (
      <ReactQueryCacheProvider queryCache={promptsCache}>
        <WrappedComponent {...props} />
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryCacheProvider>
    );
  };

  WithPromptsCache.displayName = `withPromptsCache(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;
  return WithPromptsCache;
};

export default withPromptsCache(Prompts);
