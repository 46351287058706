import React from "react";
import {RootStateType} from "../../redux/store";
import {conversionPaths} from "../../redux/selectors/userSelector";
import {connect} from "react-redux";
import {Divider, Image, Table} from "antd";
import {setWith} from "lodash";


const getFullDate = (date: string): string => {
    if (!date) return "";
    const dateAndTime = date.split('T');

    return `${dateAndTime[0].split('-').reverse().join('-')} ${dateAndTime[1].replace('Z', '')}`;
};

const getAdapterTypeName = (adapter_type) => {
    switch (adapter_type) {
        case 'face':
            return 'Face'
        case 'generate_background':
            return 'Generate background'
        case 'control':
            return 'Image and edges'
        case 'control2':
            return 'Edges'
        case 'upscale':
            return 'Upscale'
        case 'inpainting':
            return 'Inpainting'
        default:
            return "Unknown";
    }
}

const formatAdapterType = (adapter_type) => (
    <span><b>Adapter type: </b>{getAdapterTypeName(adapter_type)}</span>
)
const formatPrompt = (prompt) => (
    <span><b>Prompt: </b>{prompt}</span>
)
const getToolName = (image_app) => {
    switch (image_app) {
        case 'deep_image':
            return "Enhancer Pro";
        case 'packshot':
            return "Packshot";
        case 'generator':
            return "Generator Pro";
        case 'tool_background_generator':
            return "Tool Background Generator";
        case 'tool_avatar_creator':
            return "Tool Avatar Creator";
        case 'tool_generator':
            return "Tool Generator";
        case 'tool_remove_background':
            return "Tool Remove Background";
        case 'tool_doodle':
            return "Tool Doodle";
        case 'tool_auto_enhance':
            return "Tool Auto Enhance";
        case 'tool_real_estate_designer':
            return "Tool Real Estate Designer";
        case 'tool_upscale':
            return "Tool Upscale";
        case 'tool_business_photo':
            return "Tool Business Photo";
        case 'shoper':
            return "Shoper";
        case 'tool_document_upscale':
            return "Tool Document Upscale";
        case 'tool_product_photo':
            return "Tool Product Photo";
        case 'tool_uncrop':
            return "Tool Uncrop";
        default:
            return "Unknown Tool";
    }
};

const formatResolution = (resolution) => (
    <span><b>Resolution: </b>{resolution}</span>
)

const formatEnhancement = (enhancement) => {
    switch (enhancement) {
        case 'remove_background':
            return 'Remove background'
        case 'ganzoomnoise1-jpg90':
            return 'Denoise'
        case 'ganenhance1-jpg90':
            return 'Deblur'
        case 'clean':
            return 'Clean'
        case 'face_enhance':
            return 'Face enhance'
        case 'enhance_light_v2':
            return 'Light v2'
        case 'enhance_light':
            return 'Light'
        case 'enhance_light_contrast':
            return 'Light contrast'
        case 'enhance_color_v2':
            return 'Color v2'
        case 'enhance_color':
            return 'Color'
        case 'enhance_color_contrast':
            return 'Color contrast'
        case 'correct_wb_v2':
            return 'White balance v2'
        case 'correct_wb':
            return 'White balance'
        case 'exposure_correction':
            return 'White balance'
        default:
            return 'Unknown'
    }
}
const formatEnhancements = (enhancements) => (
    <span><b>Enhancements: </b><ul>{enhancements.map((enhancement) => <li>{formatEnhancement(enhancement)}</li>)}</ul></span>
)

const columns = [
    {
        title: "Date",
        dataIndex: "timestamp",
        key: "timestamp",
        render: (result: string, row: any) => {
            return getFullDate(result)
        }
    },
    {
        title: "Tool",
        dataIndex: "image_app",
        key: "image_app",
        render: (result: string, row: any) => {
            return getToolName(result)
        }
    },
    {
        title: "Job",
        dataIndex: "thumbnail_url",
        key: "thumbnail_url",
        render: (result: string, row: any) => {
            let img_url = result;

            if (img_url) {
                img_url = img_url.replace("minio", "localhost");
                img_url = img_url.replace(
                    "http://ain.teonite.net/neuroapi-store/",
                    "https://deep-image.ai/images/"
                );
            }

            return <a href={`https://deep-image.ai/app/application/options/images/${row.hash}`} target="_blank"
                      rel="noreferrer"><Image preview={false} width={100} src={img_url}/></a>;
        },
    },
    {
        title: "Parameters",
        dataIndex: "image_app",
        key: "image_app",
        render: (text: string, data: any) => {
            let resolution;
            if (data.width && data.height) {
                resolution = `${data.width || ''}x${data.height || ''}`
            } else if (data.width || data.height) {
                resolution = `${data.width || ''} ${data.height || ''}`
            } else {
                resolution = null
            }

            const elements = [
                ...(resolution ? [formatResolution(resolution)]: []),
            ...(data.enhancements && data.enhancements.length > 0 && data.image_app !== 'tool_auto_enhance' ? [formatEnhancements(data.enhancements)]: [])
            ]

            return (<ul>
                {elements.map(element => <li>{element}</li>)}
            </ul>)
        }
    },
    {
        title: "Generation params",
        dataIndex: "background",
        key: "background",
        render: (text: any, data: any) => {
            const elements = [
                formatPrompt(data.generate_data?.description),
                formatAdapterType(data.generate_data?.adapter_type),
            ]
            return data.generate_data?.description ? (<ul>
                {elements.map(element => <li>{element}</li>)}
            </ul>): ('N/A');
        }
    },

];

const ConversionPaths = ({conversionPaths}) => {
    return (
        <div className="conversion-paths">
            <h3>Conversion path</h3>
            <Divider/>
            <Table
                dataSource={conversionPaths}
                columns={columns}
                bordered
                rowKey={(value) => value._id}
            />
        </div>
    );
};

const mapState = (state: RootStateType) => ({
    conversionPaths: conversionPaths(state),
})

const mapDispatch = {}

export default connect(mapState, mapDispatch)(ConversionPaths);
